import { StyleSheet } from 'react-native';


export const GlobalStyles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    centerOnScreen: {
      flex: 1, 
      alignItems: 'center', 
      justifyContent: 'center'
    },
    eventFinderResults: {
      flex: 1, 
      alignItems: 'center', 
      justifyContent: 'center',
      alignSelf: 'center',
      width: 'auto',
      margin: 'auto'
    },
    colors: {
        primaryGreen: '#35BE1F',
        secondaryGreen: '#239711',
        tertiaryGreen: '#14580a',
        dirtyBlack: '#1d2033',
        textColor: '#000000',
        mainBackground: '#e9e9e9',
        footerBackground: '#000',
        headerColor: '#000'

    }
  });