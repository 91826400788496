/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEvents = /* GraphQL */ `
  query GetEvents($EventId: ID!) {
    getEvents(EventId: $EventId) {
      EventId
      event_type
      eventName
      store_id
      address
      startDate
      endDate
      schedule
      website
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $EventId: ID
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      EventId: $EventId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        EventId
        event_type
        eventName
        store_id
        address
        startDate
        endDate
        schedule
        website
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByEvent_type = /* GraphQL */ `
  query EventsByEvent_type(
    $event_type: EventType!
    $sortDirection: ModelSortDirection
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByEvent_type(
      event_type: $event_type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        EventId
        event_type
        eventName
        store_id
        address
        startDate
        endDate
        schedule
        website
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByStore_id = /* GraphQL */ `
  query EventsByStore_id(
    $store_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByStore_id(
      store_id: $store_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        EventId
        event_type
        eventName
        store_id
        address
        startDate
        endDate
        schedule
        website
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByStartDate = /* GraphQL */ `
  query EventsByStartDate(
    $startDate: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByStartDate(
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        EventId
        event_type
        eventName
        store_id
        address
        startDate
        endDate
        schedule
        website
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
