import { generateClient } from 'aws-amplify/api';
import { eventsByStartDate, listEvents } from '../src/graphql/queries'
import { useEffect } from 'react';

const client = generateClient();


export async function queryEventsByStartDate(_startDate) {
    //format startdate to awsdateformat
    return await client.graphql({
        query: eventsByStartDate,
        variables: { startDate: new Date(_startDate).toISOString().split("T")[0] }
    });
}

export async function getListOfEvents() {
    //format startdate to awsdateformat
    return await client.graphql({
        query: listEvents
    });
}