import React, { Component } from 'react';
import {
  View,
  Button
} from 'react-native';
import { GlobalStyles } from '../styles/globalStyles'
import CalendarPicker from 'react-native-calendar-picker';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStartDate: null,
      selectedEndDate: null
    };
    this.onDateChange = this.onDateChange.bind(this);
  }

  onDateChange(date, type) {
    if (type === 'END_DATE') {
      this.setState({
        selectedEndDate: date,
      });
    } else {
      this.setState({
        selectedStartDate: date,
        selectedEndDate: null,
      });
    }
  }

  render() {
    const { selectedStartDate, selectedEndDate, searchButtonEnabled } = this.state;
    const minDate = new Date(); // Today
    const startDate  =  selectedStartDate ? selectedStartDate.toString() : '';
    const endDate = selectedEndDate ? selectedEndDate.toString() : '';
    const enabled = selectedStartDate && selectedEndDate ? true: false;
    return (
      <View>
        <CalendarPicker
          startFromMonday={true}
          allowRangeSelection={true}
          minDate={minDate}
          todayBackgroundColor={GlobalStyles.colors.tertiaryGreen}
          selectedDayColor={GlobalStyles.colors.primaryGreen}
          selectedDayTextColor="#FFFFFF"
          onDateChange={this.onDateChange}
        />
        <Button
          disabled={!enabled}
          title="search"
          color={GlobalStyles.colors.primaryGreen}
          onPress={() => {
            this.props.getDates({startDate, endDate});
          }}
        />
      </View>
    );
  }
}