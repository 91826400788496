import {Text, View } from 'react-native';
import { GlobalStyles } from '../styles/globalStyles';
import CalenderPicker from '../Components/calenderpickerComponent'
import moment from 'moment';

export function eventFinder({navigation}) {
const getDates = (data) => {
    console.log('this is the date before moment: ', data.startDate);
      navigation.push('eventFinderResults', {
      "startDate": moment(data.startDate).format('L'),
      "endDate": moment(data.endDate).format('L')
    });
}
    return (
      <View style={GlobalStyles.centerOnScreen}>
        <Text>
          lets find an event
        </Text>
        <CalenderPicker
        getDates = {getDates}
        />
      </View>
    );
  }