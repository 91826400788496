import React, { Component } from 'react';
import { StyleSheet, View, Text, Linking } from 'react-native';
import { Table, TableWrapper, Row, Cell } from 'react-native-reanimated-table';

export default class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
       tableHead: props.tableHead,
       tableData: props.tableData,
       urlIndex: props.urlIndex
    }
  }

  componentDidUpdate(props){
    if(this.state.tableData != this.props.tableData){
      this.state.tableData = this.props.tableData;
      this.forceUpdate();
    }
  }

  render() {
    const state = this.state;
    const link = (data) => (
    <Text style={{color: 'blue'}}
        onPress={() => Linking.openURL(`https://${data}`)}>
    {data}
    </Text>
    );

    const convertedJsonTable = (data, tableHead) => {
        let convertedTableData = []
        if(data !== null) {
        let jsonData = JSON.parse(data);
        for(let i = 0; i < jsonData.items.length; i++) {
            let convertedRowData = []
            for(let j = 0; j < tableHead.length; j++){
                if(jsonData.items[i].hasOwnProperty(tableHead[j])){
                    convertedRowData.push(`${jsonData.items[i][tableHead[j]]}`);
                }
            }     
            convertedTableData.push(convertedRowData);
        }
      }
        return convertedTableData;
    }



    return (
      <View style={styles.container}>
        <Table borderStyle={{borderColor: 'transparent'}}>
          <Row data={state.tableHead} style={styles.head} textStyle={styles.text}/>
          {
            convertedJsonTable(state.tableData, state.tableHead).map((rowData, index) => (
              <TableWrapper key={index} style={styles.row}>
                {
                  rowData.map((cellData, cellIndex) => (
                    <Cell key={cellIndex} data={state.urlIndex === cellIndex ? link(cellData) : cellData} textStyle={styles.text}/>
                  ))
                }
              </TableWrapper>
            ))
          }
        </Table>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 16, paddingTop: 30, backgroundColor: '#fff' },
  head: { height: 40, backgroundColor: '#808B97' },
  text: { margin: 4 },
  row: { flexDirection: 'row', backgroundColor: '#FFF1C1' },
  btn: { width: 58, height: 18, backgroundColor: '#78B7BB',  borderRadius: 2 },
  btnText: { textAlign: 'center', color: '#fff' }
});