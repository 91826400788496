import {Text, View, Button } from 'react-native';
import { useEffect, useState } from 'react';
import { GlobalStyles } from '../styles/globalStyles';
import CustomTable from '../Components/tableComponent';
import { queryEventsByStartDate, getListOfEvents } from '../Components/graphqlComponent';
import moment from 'moment';


export function eventFinderResults({route, navigation}) {
    const [jsonData, setJsonData] = useState(null);
    let {startDate, endDate} = route.params;
    let colNames = ["eventName", "event_type","address","startDate", "endDate", "schedule","website"];

    function filterBydates(item) {
      let _itemStartDate =  new Date(moment(item.startDate).format('L')).getTime();
      let _itemEndDate =  new Date(moment(item.endDate).format('L')).getTime();

      let _startDate =  new Date(startDate).getTime();
      let _endDate =  new Date(endDate).getTime();


      if(_itemStartDate >= _startDate && _itemStartDate <= _endDate
        || _itemEndDate >= _startDate && _itemEndDate <= _endDate) {
        //console.log("did we return true")
        return true;
      }
      return false;
      // if (Number.isFinite(item.id) && item.id !== 0) {
      //   return true;
      // }
      // invalidEntries++;
      // return false;
    }

    useEffect(() => {
      getListOfEvents().then((response) => {
        //console.log('json of the string: ',JSON.stringify(response.data));
        let jsonString = JSON.stringify(response.data.listEvents)
        let json = JSON.parse(jsonString);
        if(json != null && json.items.length > 0) {
          let filteredList = json.items.filter(filterBydates);
          if(filteredList.length > 0) {
            setJsonData(JSON.stringify({items: json.items.filter(filterBydates)}));
          }
        }
      });
      // queryEventsByStartDate(startDate).then( (response) => {
      //   //console.log('json of the string: ',JSON.stringify(response.data.eventsByStartDate));
      //   let jsonString = JSON.stringify(response.data.eventsByStartDate)
      //   let json = JSON.parse(jsonString);
      //   if(json != null && json.items.length > 0) {
      //     setJsonData(jsonString);
      //   }
      // });
    }, [])
    if(jsonData != null) {
    return (
      <View 
      //style={GlobalStyles.eventFinderResults}
      >
        <Text>Events from: {startDate} to {endDate} </Text>
        <CustomTable tableHead={colNames} 
        tableData={jsonData}
        urlIndex = {6}
        />
        <Button title="Go back" onPress={() => navigation.goBack()}/>
      </View>
    );
    } else {
      return (
        <View>
          <Text>No events found between {startDate} and {endDate}  </Text>
        </View>
      )
    }
  }