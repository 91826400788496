import {Text, View, Button } from 'react-native';
import { GlobalStyles } from '../styles/globalStyles';
export function HomeScreen({ navigation }) {
    return (
      <View style={GlobalStyles.centerOnScreen}>
        <Text>The Comic Hood</Text>
        <Button
          title="find an Event now!"
          color={GlobalStyles.colors.primaryGreen}
          onPress={() => navigation.navigate('eventFinder')}
        />
      </View>
    );
  }