import { Button } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { eventFinder } from './pages/eventFinder';
import { HomeScreen } from './pages/homeScreen';
import { GlobalStyles } from './styles/globalStyles';
import { eventFinderResults } from './pages/EventFinderResults';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './src/amplifyconfiguration.json';

Amplify.configure(amplifyconfig);

const Stack = createNativeStackNavigator();


export default function App() {

  return (
    <NavigationContainer>
    <Stack.Navigator initialRouteName="Home"
    screenOptions={{
      headerStyle: {
        backgroundColor: GlobalStyles.colors.headerColor//'#f4511e',
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: 'bold',
      }
        }}>
      <Stack.Screen name="Home" 
      component={HomeScreen} 
      options={{
        title: 'The Comic Hood'
        // headerRight: () => (
        //   <Button
        //     onPress={() => alert('This is a button!')}
        //     title="Info"
        //     color={GlobalStyles.colors.primaryGreen}
        //   />
        // )
      }}
       />
      <Stack.Screen name="eventFinder" 
      component={eventFinder}
      options={({ navigation }) => ({
        headerLeft: () => (
          <Button title="Go to Home" onPress={() => navigation.navigate('Home')} />
          //<Button title="Go to Results" onPress={() => navigation.navigate('EventFinderResults')} />
        )
      })}
       />
      <Stack.Screen name="eventFinderResults" 
      component={eventFinderResults}
      //initialParams={{startDate: "nov 12", endDate: "nov 15"}}
      options={({ navigation }) => ({})}
       />
    </Stack.Navigator>
  </NavigationContainer>
  );
}
